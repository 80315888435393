import { AccountMinimalView } from '@accounting/common';
import { DatePipe, formatDate } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IEnvironmentConfig, SearchResponse } from '@autobot/core';
import { Assembly, MedicalProtocol, Template } from '@forms/common';
import {
  Case,
  CaseAdvanceSearchResult,
  CaseSearchResult,
  CaseType,
  CreateCallDTO,
  CreateCaseDTO,
  CreateCaseSerchDTO,
  DocumentHistory,
  EmailHistory,
  FollowUp,
  Protocols,
  SimilarCaseDTO,
  DocumentType,
  Call,
  UnAssignedCases,
} from '@nfr/common';
import {
  Observable,
  catchError,
  forkJoin,
  retry,
  switchMap,
  throwError,
} from 'rxjs';
import { first, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CaseApiService {
  constructor(
    private http: HttpClient,
    @Inject('ENVIRONMENT')
    private environment: IEnvironmentConfig
  ) {}

  apiURL = this.environment.apiConfig.casesUri + '/api';

  static removeTriageAbcdProtocol(model: any, action: Protocols): Object {
    let code = action.code.toLowerCase();
    let protocols = model?.triage?.protocol ?? {};
    let current = {};
    Object.keys(protocols).forEach((key) => {
      if (!key.toLowerCase().includes(code)) current[key] = protocols[key];
    });
    return current;
  }

  static removePlanOfCareProtocol(model: any, action: Protocols): Object {
    let code = action.code.toLowerCase();
    let protocols = model?.planofcare?.protocol ?? {};
    let current = {};
    Object.keys(protocols).forEach((key) => {
      if (!key.toLowerCase().includes(code)) current[key] = protocols[key];
    });
    return current;
  }

  saveCase(id: string, data: any, tenant: string = 'default') {
    const _url = `${this.apiURL}/cases/${id}`;
    const options = {
      headers: {
        Tenant: tenant,
      },
    };
    return this.http
      .put<Case>(_url, data, options)
      .pipe(retry(1), catchError(this.httpError.bind(this)));
  }

  saveNote(id: string, data: any, tenant: string = 'default') {
    const _url = `${this.apiURL}/cases/${id}/notes`;

    const options = {
      headers: {
        Tenant: tenant,
      },
    };
    return this.http
      .put<Case>(_url, data, options)
      .pipe(retry(1), catchError(this.httpError.bind(this)));
  }
  reopenCase(id: string, remarks: any, tenant: string = 'default') {
    const _url = `${this.apiURL}/cases/${id}/reopen`;
    const options = {
      headers: {
        Tenant: tenant,
      },
    };
    let reason: any = new Object();
    reason.remarks = remarks;
    return this.http
      .put<any>(_url, reason, options)
      .pipe(retry(1), catchError(this.httpError.bind(this)));
  }

  createCall(dto: CreateCallDTO, tenant: string = 'default') {
    const _url = `${this.apiURL}/calls`;
    const options = {
      headers: {
        Tenant: tenant,
      },
    };

    return this.http
      .post<CreateCallDTO>(_url, dto, options)
      .pipe(retry(1), catchError(this.httpError.bind(this)));
  }

  createCase(dto: CreateCaseDTO, tenant: string = 'default') {
    const _url = `${this.apiURL}/cases`;
    const options = {
      headers: {
        Tenant: 'default',
      },
    };

    return this.http
      .post<Case>(_url, dto, options)
      .pipe(retry(1), catchError(this.httpCaseError.bind(this)));
  }

  loadCase(id: string): Observable<Case> {
    const _url = `${this.apiURL}/cases/${id}`;

    return this.http
      .get<Case>(_url, {
        headers: {
          Tenant: 'default',
        },
      })
      .pipe(retry(1), catchError(this.httpError.bind(this)));
  }

  getCases(tenant: string = 'default'): Observable<Case[]> {
    const _url = `${this.apiURL}/cases`;
    const options = {
      headers: {
        Tenant: tenant,
      },
    };
    return this.http
      .get<Case[]>(_url, options)
      .pipe(retry(1), catchError(this.httpError.bind(this)));
  }

  loadDesktop(): Observable<any> {
    const _url = 'assets/data/desktop-default.json';
    return this.http.get<any>(_url);
  }

  searchCasesByAccount(
    searchText: string,
    account: AccountMinimalView
  ): Observable<SearchResponse<CaseSearchResult>> {
    const _url = `${this.apiURL}/cases`;
    const params = new HttpParams().set('searchText', searchText);

    const options = {
      headers: {
        Tenant: account.tenant,
      },
      params,
    };
    return this.http.get<CaseSearchResult[]>(_url, options).pipe(
      map((results) => {
        const final = results.filter((e) => e.accountId == account.id);

        return {
          items: final,
          total: final.length,
        };
      }),
      retry(1),
      catchError(this.httpError.bind(this))
    );
  }

  advanceSearchCases(
    dto: CreateCaseSerchDTO
  ): Observable<SearchResponse<CaseAdvanceSearchResult>> {
    datepipe: DatePipe;
    const _url = `${this.apiURL}/cases/search`;
    const params = new HttpParams()
      .set('CaseRef', dto.caseRef)
      .set('AccountId', dto.accountId)
      .set('Status', dto.status)
      .set('caseType', dto.caseType)
      .set('FirstName', dto.firstName)
      .set('LastName', dto.lastName)
      .set('EmployeeId', dto.empID)
      .set(
        'DateInjury',
        dto.dateInjury ? formatDate(dto.dateInjury, 'yyyy-MM-dd', 'en-US') : ''
      )
      .set('SSN', dto.ssn.toString())
      .set(
        'CreateDateTo',
        dto.createDateTo
          ? formatDate(dto.createDateTo, 'yyyy-MM-dd', 'en-US')
          : ''
      )
      .set(
        'CreateDateFrom',
        dto.createDateFrom
          ? formatDate(dto.createDateFrom, 'MM-dd-yyyy', 'en-US')
          : ''
      )
      .set(
        'DateOfBirth',
        dto.dateOfBirth
          ? formatDate(dto.dateOfBirth, 'yyyy-MM-dd', 'en-US')
          : ''
      )
      .set('LocationCode', dto.locationCode.toString())
      .set('CreatedBy', dto.createdBy.toString())
      .set('PageNumber', dto.pageNumber.toString())
      .set('PageSize', dto.pageSize.toString())
      .set('SortField', dto.sortField)
      .set('SortDirection', dto.sortDirection);
    const options = {
      headers: {
        Tenant: dto.tenant,
      },
      params,
    };
    return this.http.get<CaseAdvanceSearchResult[]>(_url, options).pipe(
      map((results) => {
        const final = results;
        let totalRecords = final.length;
        if (results != null && results.length > 0)
          totalRecords = results.find(
            (element) => element.totalRecords > 0
          ).totalRecords;

        return {
          items: final,
          total: totalRecords,
        };
      }),
      retry(1),
      catchError(this.httpError.bind(this))
    );
  }
  searchCases(
    searchText: string,
    tenant: string = 'default'
  ): Observable<SearchResponse<CaseSearchResult>> {
    const _url = `${this.apiURL}/cases`;
    const params = new HttpParams().set('searchText', searchText);

    const options = {
      headers: {
        Tenant: tenant,
      },
      params,
    };
    return this.http.get<CaseSearchResult[]>(_url, options).pipe(
      map((results) => {
        return {
          items: results,
          total: results.length,
        };
      }),
      retry(1),
      catchError(this.httpError.bind(this))
    );
  }
  loadSearchCases(dto: CreateCaseSerchDTO): Observable<CaseSearchResult[]> {
    const _url = `${this.apiURL}/case/search/`;
    const params = new HttpParams();

    for (var key in dto) {
      if (dto.hasOwnProperty(key)) params.set(key, dto[key]);
    }
    const options = {
      headers: {
        Tenant: dto.tenant,
      },
      params,
    };
    return this.http
      .get<CaseSearchResult[]>(_url, options)
      .pipe(retry(1), catchError(this.httpError.bind(this)));
  }
  searchCasesPaged(
    searchText: string,
    tenant: string = 'default',
    sortColumn,
    sortDirection,
    pageIndex
  ): Observable<CaseSearchResult[]> {
    const _url = `${this.apiURL}/cases`;
    const params = new HttpParams().set('searchText', searchText);

    const options = {
      headers: {
        Tenant: tenant,
      },
      params,
    };
    return this.http
      .get<CaseSearchResult[]>(_url, options)
      .pipe(retry(1), catchError(this.httpError.bind(this)));
  }
  httpCaseError(error: any) {
    let msg = '';
    if (error.error instanceof ErrorEvent) {
      // client side error
      msg = error.error.message;
    } else if (error.error instanceof Object) {
      msg = error.error['caseModel.CaseRef'][0];
    } else {
      // server side error
      msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    return throwError(msg);
  }

  httpError(error: any) {
    let msg = '';
    if (error.error instanceof ErrorEvent) {
      // client side error
      msg = error.error.message;
    } else {
      // server side error
      msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    return throwError(msg);
  }

  loadSingleCaseLocal(id: string): Observable<Case> {
    return this.http
      .get<Case[]>('assets/data/fakes/fake.cases.json')
      .pipe(map((o) => o.filter((c) => c.id == id)[0]));
  }
  loadCasesLocal(): Observable<Case[]> {
    return this.http.get<Case[]>('assets/data/fakes/fake.cases.json');
  }
  searchSimilarCase(dto: SimilarCaseDTO, tenant: string = 'default') {
    const params = new HttpParams()
      .set('AccountId', dto.accountId)
      .set('EmployeeFirstName', dto.employeeFirstName)
      .set('EmployeeLastName', dto.employeeLastName)
      .set('DOI', dto.doi)
      .set('WithinDays', dto.withinDays);

    const _url = `${this.apiURL}/cases/related`;
    const options = {
      headers: {
        Tenant: tenant,
      },
      params,
    };

    return this.http.get<any[]>(_url, options);
  }

  softDelete(id: string, remarks: any, tenant: string = 'default') {
    const _url = `${this.apiURL}/cases/${id}`;
    let reason: any = new Object();
    reason.remarks = remarks;
    const options = {
      headers: {
        Tenant: tenant,
      },
      body: reason,
    };

    return this.http
      .delete<any>(_url, options)
      .pipe(retry(1), catchError(this.httpError.bind(this)));
  }

  createCaseDocumentHistory(dto: DocumentHistory, tenant: string = 'default') {
    const _url = `${this.apiURL}/documentactionhistory`;
    const options = {
      headers: {
        Tenant: tenant,
      },
    };

    return this.http
      .post<DocumentHistory>(_url, dto, options)
      .pipe(retry(1), catchError(this.httpCaseError.bind(this)));
  }

  createDocumentEmailHistory(dto: EmailHistory, tenant: string = 'default') {
    const _url = `${this.apiURL}/emailqueue`;
    const options = {
      headers: {
        Tenant: tenant,
      },
    };

    return this.http
      .post<EmailHistory>(_url, dto, options)
      .pipe(retry(1), catchError(this.httpCaseError.bind(this)));
  }

  getDocumentEmailHistory(
    caseid: string,
    documenttype: string,
    tenant: string = 'default'
  ): Observable<EmailHistory[]> {
    const _url = `${this.apiURL}/emailqueue/${caseid}/${documenttype}`;

    return this.http
      .get<EmailHistory[]>(_url, {
        headers: {
          Tenant: tenant,
        },
      })
      .pipe(retry(1), catchError(this.httpError.bind(this)));
  }
  updateCaseType(id: string, caseType: CaseType, tenant: string = 'default') {
    const _url = `${this.apiURL}/cases/${id}/casetype?caseType=${caseType}`;
    const options = {
      headers: {
        Tenant: tenant,
      },
    };
    // let request: any = new Object();
    // request.caseType = caseType;
    return this.http
      .put<any>(_url, null, options)
      .pipe(retry(1), catchError(this.httpError.bind(this)));
  }

  getDocumentActionHistory(
    caseid: string,
    tenant: string = 'default'
  ): Observable<any[]> {
    const _url = `${this.apiURL}/documentactionhistory/${caseid}`;

    return this.http
      .get<any[]>(_url, {
        headers: {
          Tenant: tenant,
        },
      })
      .pipe(retry(1), catchError(this.httpError.bind(this)));
  }

  getCaseDocumentPreview(
    caseid: string,
    formtype: string,
    tenant: string = 'default'
  ): Observable<any[]> {
    const _url = `${this.apiURL}/document/${caseid}/preview/${formtype}`;

    return this.http
      .get<any[]>(_url, {
        headers: {
          Tenant: tenant,
        },
      })
      .pipe(retry(1), catchError(this.httpError.bind(this)));
  }
  getCaseDocumentDownload(
    caseid: string,
    formtype: DocumentType,
    tenant: string = 'default'
  ): Observable<any[]> {
    const _url = `${this.apiURL}/document/${caseid}/download/${formtype}`;

    return this.http
      .get<any[]>(_url, {
        headers: {
          Tenant: tenant,
        },
      })
      .pipe(retry(1), catchError(this.httpError.bind(this)));
  }

  GetCaseStatus(caseid: string, tenant: string = 'default'): Observable<any[]> {
    const _url = `${this.apiURL}/cases/${caseid}/status`;

    return this.http
      .get<any[]>(_url, {
        headers: {
          Tenant: tenant,
        },
      })
      .pipe(retry(1), catchError(this.httpError.bind(this)));
  }

  GetCallActionHistoryById(
    caseid: string,
    tenant: string = 'default'
  ): Observable<any[]> {
    const _url = `${this.apiURL}/cases/${caseid}/callhistory`;
    console.log(_url);

    return this.http
      .get<any[]>(_url, {
        headers: {
          Tenant: tenant,
        },
      })
      .pipe(retry(1), catchError(this.httpError.bind(this)));
  }

  loadFollowUps(caseId: string): Observable<FollowUp[]> {
    const _url = `${this.apiURL}/followUps/${caseId}`;

    return this.http
      .get<FollowUp[]>(_url, {
        headers: {
          Tenant: 'default',
        },
      })
      .pipe(retry(1), catchError(this.httpError.bind(this)));
  }

  saveFollowUp(data: FollowUp, tenant: string = 'default') {
    const _url = `${this.apiURL}/followUps`;
    const options = {
      headers: {
        Tenant: tenant,
      },
    };
    return this.http
      .post<FollowUp>(_url, data, options)
      .pipe(retry(1), catchError(this.httpError.bind(this)));
  }

  updateFollowUp(id: string, data: FollowUp, tenant: string = 'default') {
    const _url = `${this.apiURL}/followUps/${id}`;
    const options = {
      headers: {
        Tenant: tenant,
      },
    };
    return this.http
      .put<FollowUp>(_url, data, options)
      .pipe(retry(1), catchError(this.httpError.bind(this)));
  }
  checkduplicatePhoneSystem(phoneSystemID: string): Observable<Call[]> {
    const _url = `${this.apiURL}/calls/${phoneSystemID}`;

    return this.http
      .get<Call[]>(_url, {
        headers: {
          Tenant: 'default',
        },
      })
      .pipe(retry(1), catchError(this.httpError));
  }
}
